
import { Options, Vue } from 'vue-class-component'
import PlanStepCard from '@/components/PlanStepCard.vue'
import service from '@/utils/request'
import { ApplyStatus, Plan as PlanModel, PlanStatus, Task } from '@/models/plan'
import dayjs from 'dayjs'
import { AuthStore } from '@/store/modules/auth'
import { Dialog, Toast } from 'vant'
import wx from 'weixin-js-sdk-ts'

@Options({
  components: {
    PlanStepCard
  }
})
export default class Practice extends Vue {
  applyStatus = ApplyStatus
  planStatus = PlanStatus
  authStore = AuthStore
  plan: PlanModel = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  tasks: Task[] = []
  dailyTasks: Task[] = []

  get isTeacher () {
    return AuthStore.isTeacher
  }

  get day () {
    if (this.plan.status === PlanStatus.IN_PROGRESS) {
      const date1 = dayjs(this.plan.startTime)
      const date2 = dayjs(Date.now())
      return date2.diff(date1, 'day') + 1
    }
    return 0
  }

  get planTitle () {
    if (this.plan.name === '') {
      return '每日计划练习'
    } else {
      const title = this.plan.name + (this.day > 0 ? ':第' + this.day + '天' : ':未开始')
      return title
    }
  }

  mounted () {
    console.log('auth == ', this.authStore.name)
    console.log('auth == ', this.authStore.phone)

    if (this.isTeacher) {
      if (this.authStore.planId) {
        this.loadTask(this.authStore.planId)
        this.loadPlanDetail(this.authStore.planId)
      } else {
      }
    } else {
      this.loadPlan()
    }
    this.loadDailyTask()
  }

  loadPlanDetail (planId: number) {
    service.get('/mindup/mini/plans/' + planId, {}).then(res => {
      this.plan = res.data
    })
  }

  loadPlan () {
    service.get('/mindup/mini/plans/current-plan-info', {}).then(res => {
      if (res.data === null) {

      } else {
        this.plan = res.data
        if (this.plan && this.plan.status === this.planStatus.IN_PROGRESS && this.plan.applyStatus === this.applyStatus.AUDIT_PASSED) {
          this.loadTask(this.plan.id)
        }
      }
    })
  }

  loadTask (planId: number) {
    service.get('/mindup/mini/tasks/list/' + planId, {}).then(res => {
      this.tasks = res.data
    })
  }

  loadDailyTask () {
    service.get('/mindup/mini/tasks/daily-task/list', {}).then(res => {
      this.dailyTasks = res.data
    })
  }

  tapSelectPlan () {
    if (this.authStore.name === undefined || this.authStore.name === 'string' || !this.authStore.name) {
      wx.miniProgram.navigateTo({ url: '/pages/login/login' })
    } else {
      this.$router.push({ name: 'PlanSelect' })
    }
  }

  tapPractice () {
    this.$router.push({ name: 'StandardPractice' })
  }

  // tapPlanInfo () {
  //   this.$router.push({
  //     name: 'PlanDetail',
  //     params: { planId: this.plan.id }
  //   })
  // }

  tapPlanChange () {
    this.$router.push({ name: 'PlanChange' })
  }

  tapDiary () {
    console.log('tapDiary', this.plan.id)
    this.$router.push({
      name: 'DiaryWrite',
      params: {
        planId: this.plan.id
      }
    })
  }

  // tapPlanDetail () {
  //   this.$router.push({
  //     name: 'PlanDetail',
  //     params: { planId: this.plan.id }
  //   })
  // }

  cancelDiary (index: number) {
    Dialog.confirm({
      title: '取消此每日练习',
      message: '移除后，此项自定义每日练习将不再出现在每日练习计划中。',
      confirmButtonText: '取消练习'
    })
      .then(() => {
        service.post('/mindup/mini/tasks/' + this.dailyTasks[index].id + '/cancel-daily-practice', {}).then(res => {
          Toast.success('取消成功')
          this.loadDailyTask()
        })
      })
      .catch(() => {
        // on cancel
      })
  }

  tapSetting () {
    this.$router.push({ name: 'PracticeDailyList' })
  }
}
