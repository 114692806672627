
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Task, TaskPracticeType, TaskType } from '@/models/plan'
import { Toast } from 'vant'
import service from '@/utils/request'

@Options({
  components: {}
})
export default class PlanStepCard extends Vue {
  @Prop() task!: Task
  @Prop() first = false
  @Prop() last = false
  @Prop() single = false
  taskType = TaskType
  taskPracticeType = TaskPracticeType

  getTypeStr () {
    if (this.task.type === this.taskType.PLAN_PRACTICE_TASK || this.task.type === this.taskType.DAILY_PRACTICE_TASK) {
      if (this.task.practiceType === this.taskPracticeType.LIFE_PRACTICE) {
        return '生活练习'
      } else {
        return '正式练习'
      }
    } else if (this.task.type === this.taskType.SURVEY_TASK) {
      return '问卷'
    }
  }

  tapTask () {
    if (this.task.type === this.taskType.PLAN_PRACTICE_TASK || this.task.type === this.taskType.DAILY_PRACTICE_TASK) {
      if (this.task.practiceType === this.taskPracticeType.LIFE_PRACTICE) {
        this.$router.push({
          name: 'LifePractice',
          params: {
            practiceId: this.task.relatedId,
            taskId: this.task.id,
            isDaily: this.task.type === this.taskType.DAILY_PRACTICE_TASK ? '1' : '0'
          }
        })
      } else {
        this.$router.push({
          name: 'StandardPractice',
          params: {
            practiceId: this.task.relatedId,
            taskId: this.task.id,
            isDaily: this.task.type === this.taskType.DAILY_PRACTICE_TASK ? '1' : '0'
          }
        })
      }
    } else {
      if (!this.task.isCompleted) {
        // 问卷
        this.$router.push({
          name: 'PracticeTest',
          params: {
            surveyId: this.task.relatedId,
            taskId: this.task.id
          }
        })
      } else {
        Toast.success({
          message: '任务已完成',
          duration: 2000
        })
      }
    }
  }

  @Emit('cancelDiary')
  tapDiaryPractice () {
    console.log('cancelDiary')
  }
}
