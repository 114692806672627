
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PlayList } from '@/models/practice'
import dayjs from 'dayjs'

@Options({
  components: {}
})
export default class AudioCard extends Vue {
  @Prop() isShowHeart = false
  @Prop() play!: PlayList
  @Prop() isCurrent = false
  @Prop() isPlay = false
  @Prop() islast = false

  getDate (date: any) {
    return dayjs(date).format('M月D日 HH:mm')
  }
}
