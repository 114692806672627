
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PlayCollectList } from '@/models/practice'
import dayjs from 'dayjs'
import service from '@/utils/request'
import { Dialog, Toast } from 'vant'

@Options({
  components: {}
})
export default class CollectCard extends Vue {
  @Prop() isShowHeart = false
  @Prop() play!: PlayCollectList
  @Prop() isCurrent = false
  @Prop() islast = false

  getDate (date: any) {
    return dayjs(date).format('M月D日 HH:mm')
  }

  tapHeart () {
    Dialog.confirm({
      title: '您要取消收藏该练习吗？',
      message: '',
      confirmButtonText: '确定'
    })
      .then(() => {
        // on confirm
        service.post('/mindup/mini/practice/' + this.play.sourceId + '/collect-or-cancel', {}).then(res => {
          this.play.isCollect = res.data.isCollected
          this.$emit('refresh')
        })
      })
      .catch(() => {
        // on cancel
      })
  }
}
