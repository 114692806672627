
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/request'
import dayjs from 'dayjs'
import { Message } from '@/models/message'
import { ApplyStatus, Plan as PlanModel, PlanStatus, Task } from '@/models/plan'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {}
})
export default class Information extends Vue {
  loadingType = 0
  messageList: Message[] = []
  isLoaded = false
  state = {
    list: [],
    loading: false,
    finished: false,
    refreshing: false
  }

  params = {
    pageNum: 1,
    pageSize: 10,
    sort: '-createTime'
  }

  applyStatus = ApplyStatus
  planStatus = PlanStatus
  authStore = AuthStore
  plan: PlanModel = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  get isTeacher () {
    return AuthStore.isTeacher
  }

  mounted () {
    console.log('auth == ', this.authStore.name)
    console.log('auth == ', this.authStore.phone)

    if (this.isTeacher) {

    } else {
      this.loadPlan()
    }
    this.loadData()
  }

  loadPlan () {
    service.get('/mindup/mini/plans/current-plan-info', {}).then(res => {
      if (res.data === null) {
      } else {
        this.plan = res.data
      }
    })
  }

  loadData () {
    service.get('/mindup/mini/messages/received-message/list', {
      params: this.params
    }).then(res => {
      this.isLoaded = true
      this.state.refreshing = false
      if (res.data.content.length > 0) {
        this.messageList = this.messageList.concat(res.data.content)
        console.log(this.messageList)
        this.state.loading = false
      } else {
        this.state.finished = true
      }
      this.params.pageNum += 1
    })
  }

  getDate (date: string) {
    return dayjs(date).format('M月D日')
  }

  goToDetail (index: number) {
    this.$router.push({
      name: 'InformationDetail',
      params: { noteId: this.messageList[index].note.id }
    })

    service.post('/mindup/mini/messages/' + this.messageList[index].id + '/set-read', {}).then(res => {
      this.messageList[index].isRead = true
    })
  }

  tapDiary () {
    console.log('tapDiary', this.plan)
    if (this.plan.id > 0) {
      this.$router.push({
        name: 'DiaryWrite',
        params: {
          planId: this.plan.id
        }
      })
    } else {
      this.$router.push({
        name: 'DiaryWrite'
      })
    }
  }
}
