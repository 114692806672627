
import { Options, Vue } from 'vue-class-component'
import AudioCard from '@/components/AudioCard.vue'
import CollectCard from '@/components/CollectCard.vue'
import service from '@/utils/request'
import { Content, PlayCollectList, PlayList } from '@/models/practice'
import { Toast } from 'vant'

@Options({
  components: {
    AudioCard,
    CollectCard
  }
})
export default class Collect extends Vue {
  activeName = 'history'
  playList: PlayList[] = []
  collectPlayList: PlayCollectList[] = []
  currentPlay: PlayList = {
    createTime: '',
    id: 0,
    practiceCover: '',
    practiceId: 0,
    practiceName: '',
    practiceContent: [],
    isDailyPractice: false
  }

  currentCollectPlay: PlayCollectList = {
    cover: '',
    id: 0,
    name: '',
    sourceId: 0,
    type: '',
    userId: 0,
    isCollect: true,
    content: []
  }

  isPlay = false
  isCollect = false
  duration = ''

  get audioSrc () {
    if (this.currentPlay.practiceContent.length > 0) {
      return this.currentPlay.practiceContent[0].url
    } else {
      return ''
    }
  }

  get collectAudioSrc () {
    if (this.currentCollectPlay.content.length > 0) {
      return this.currentCollectPlay.content[0].url
    }
    return ''
  }

  mounted () {
    this.loadData()

    const audio = this.$refs.audio as HTMLAudioElement
    audio.addEventListener('durationchange', res => {
      this.duration = this.getTime(audio.duration)
    })
  }

  getTime (duration: number) {
    let sec = ''
    let min = ''
    sec = parseInt((duration % 60) + '') + ''
    min = parseInt((duration / 60) + '') + ''
    if (parseInt(sec) < 10) {
      sec = '0' + sec
    }
    if (parseInt(min) < 10) {
      min = '0' + min
    }
    return ' ' + min + ':' + sec
  }

  loadData () {
    service.get('/mindup/mini/my-data/practice-played-logs/list', {
      params: {
        sort: '-updateTime'
      }
    }).then(res => {
      this.playList = res.data.content
      if (this.playList.length > 0) {
        this.currentPlay = this.playList[0]
      }
    })

    service.get('/mindup/mini/my-data/practice-collections/list', {}).then(res => {
      this.collectPlayList = res.data.content
      this.collectPlayList.forEach((item: any) => (item.isCollect = true))
    })
  }

  tapPlayList (index: number) {
    console.log(index)
  }

  tapPlay () {
    console.log('tapPlay')
    if (this.isPlay) {
      (this.$refs.audio as HTMLAudioElement).pause()
    } else {
      (this.$refs.audio as HTMLAudioElement).play()
    }
    this.isPlay = !this.isPlay
  }

  play () {
    console.log('this.audioSrc', this.audioSrc)
    console.log('this.collectAudioSrc', this.collectAudioSrc)

    const audio = this.$refs.audio as HTMLAudioElement
    audio.pause()
    this.duration = this.getTime(audio.duration)
    this.$nextTick(() => {
      this.isPlay = true
      audio.play()
    })
  }

  tapRecordAudio (index: number) {
    // this.$nextTick(() => {
    //   this.currentPlay = this.playList[index]
    //   this.isCollect = false
    //   this.play()
    // })
    if (!this.isCollect) {
      if (this.currentPlay !== this.playList[index]) {
        this.$nextTick(() => {
          this.currentPlay = this.playList[index]
          this.isCollect = false
          this.play()
        })
      }
    } else {
      this.$nextTick(() => {
        this.currentPlay = this.playList[index]
        this.isCollect = false
        this.play()
      })
    }

    // if (this.currentPlay !== this.playList[index]) {
    //   this.$nextTick(() => {
    //     this.currentPlay = this.playList[index]
    //     this.isCollect = false
    //     this.play()
    //   })
    // }
    // else {
    //   this.play()
    // }
  }

  tapCollectAudio (index: number) {
    if (this.isCollect) {
      if (this.currentCollectPlay !== this.collectPlayList[index]) {
        this.$nextTick(() => {
          this.currentCollectPlay = this.collectPlayList[index]
          this.isCollect = true
          this.play()
        })
      }
    } else {
      this.$nextTick(() => {
        this.currentCollectPlay = this.collectPlayList[index]
        this.isCollect = true
        this.play()
      })
    }
    // this.$nextTick(() => {
    //   this.currentCollectPlay = this.collectPlayList[index]
    //   this.isCollect = true
    //   this.play()
    // })
    // if (this.currentCollectPlay !== this.collectPlayList[index]) {
    //   this.$nextTick(() => {
    //     this.currentCollectPlay = this.collectPlayList[index]
    //     this.isCollect = true
    //     this.play()
    //   })
    // }
  }

  ended () {
    this.isPlay = false
    Toast.success({
      message: '自动跳转',
      onClose: () => {
        this.$router.push({
          name: 'DiaryWrite'
        })
      }
    })
  }
}
